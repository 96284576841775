import { HttpsCallableResult } from "firebase/functions";
import { useCallableFn } from "../utils/useCallableFn";

const methodName = "v1_subscription_confirm" as const;

export type ConfirmSubscriptionResult = HttpsCallableResult<{ id: string }>;
export type ConfirmSubscriptionData = {
  subscriptionId: string;
};

export const useConfirmSubscription = () =>
  useCallableFn<ConfirmSubscriptionData, ConfirmSubscriptionResult>(methodName);
