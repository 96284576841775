import { HttpsCallableResult } from "firebase/functions";
import { useCallableFn } from "../utils/useCallableFn";

const methodName = "v1_subscription_cancel" as const;

export type CancelSubscriptionResult = HttpsCallableResult<{ id: string }>;
export type CancelSubscriptionData = {};

export const useCancelSubscription = () =>
  useCallableFn<CancelSubscriptionData, CancelSubscriptionResult>(methodName);
