import { HttpsCallableResult } from "firebase/functions";
import { useCallableFn } from "../utils/useCallableFn";

const methodName = "v1_admin_delete_public_template" as const;

export type DeletePublicTemplateResult = HttpsCallableResult<{ id: string }>;
export type DeletePublicTemplateData = {
  id: string;
};

export const useDeletePublicTemplate = () =>
  useCallableFn<DeletePublicTemplateData, DeletePublicTemplateResult>(
    methodName
  );
