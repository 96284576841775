import { HttpsCallableResult } from "firebase/functions";
import { useCallableFn } from "../utils/useCallableFn";

const methodName = "v1_users_delete_domain" as const;

export type DeleteDomainResult = HttpsCallableResult<{ id: string }>;
export type DeleteDomainData = {
  regExp: string;
};

export const useDeleteDomain = () =>
  useCallableFn<DeleteDomainData, DeleteDomainResult>(methodName);
