import { BarChart } from "@mui/x-charts/BarChart";
import "./StatisticsRoute.css";
import Loader from "../components/Loader";
import { UserModel } from "../models/database/User";
import { useEffect, useState } from "react";
import { User } from "firebase/auth";
import { OrientationState } from "react-use/lib/useOrientation";
import { Typography } from "@mui/material";

function StatisticsRoute({
  authUser,
  user,
  isLoadingUser,
  pageOrientation,
}: {
  authUser: User | null | undefined;
  user: UserModel | undefined;
  isLoadingUser: boolean;
  pageOrientation: OrientationState;
}) {
  const [stat, setStat] = useState(
    [] as { year: string; month: string; requests: number }[]
  );

  useEffect(() => {
    if (user?.statistics?.requests) {
      const st = [] as typeof stat;
      for (const year of Object.keys(user.statistics.requests).sort()) {
        for (const month of Object.keys(
          user.statistics.requests[year]
        ).sort()) {
          st.push({
            year,
            month,
            requests: user.statistics.requests[year][month] || 0,
          });
        }
      }
      setStat(st);
    }
  }, [user]);

  const valueFormatter = (value: number) => `${value} req/month`;

  const recersedStat = [...stat].reverse();

  return authUser?.emailVerified && !isLoadingUser && user ? (
    <div className="StatisticsRoute">
      <center>
        {stat.length ? (
          ["portrait-primary", "portrait"].includes(pageOrientation.type) ? (
            <BarChart
              dataset={recersedStat}
              yAxis={[
                {
                  scaleType: "band",
                  data: recersedStat.map((e) => `${e.month}.${e.year}`),
                },
              ]}
              series={[
                {
                  dataKey: "requests",
                  valueFormatter,
                },
              ]}
              layout={"horizontal"}
              width={window.innerWidth - 100}
              height={window.innerHeight - 200}
            />
          ) : (
            <BarChart
              dataset={stat}
              xAxis={[
                {
                  scaleType: "band",
                  data: stat.map((e) => `${e.month}.${e.year}`),
                },
              ]}
              series={[
                {
                  dataKey: "requests",
                  valueFormatter,
                },
              ]}
              layout="vertical"
              width={window.innerWidth - 100}
              height={window.innerHeight - 200}
            />
          )
        ) : (
          <Typography>You haven't made any requests yet.</Typography>
        )}
      </center>
    </div>
  ) : (
    <Loader />
  );
}

export default StatisticsRoute;
