import { HttpsCallableResult } from "firebase/functions";
import { useCallableFn } from "../utils/useCallableFn";

const methodName = "v1_delete_template" as const;

export type DeleteTemplateResult = HttpsCallableResult<{ id: string }>;
export type DeleteTemplateData = {
  id: string;
};

export const useDeleteTemplate = () =>
  useCallableFn<DeleteTemplateData, DeleteTemplateResult>(methodName);
