import {
  Snackbar,
  Alert,
  Card,
  Typography,
  CardContent,
  CardActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Box,
} from "@mui/material";
import "./SubscriptionRoute.css";
import Loader from "../components/Loader";
import { User } from "firebase/auth";
import { UserModel } from "../models/database";
import { useState } from "react";
import moment, { Moment, isMoment } from "moment-timezone";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { subscriptionsTypes } from "../models/database/SubscriptionType";
import { useCancelSubscription } from "../methods/cancelSubscription";
import { useConfirmSubscription } from "../methods/confirmSubscription";
import { useCreateSubscription } from "../methods/createSubscription";
import CheckIcon from "@mui/icons-material/Check";
import { getErrorFromCallable } from "../utils/getErrorFromCallable";
import FirebaseAnalytics from "../services/FirebaseAnalytics";
import { logEvent } from "firebase/analytics";

const getDateStr = (date: Moment | any) => {
  if (isMoment(date)) return date.format("YYYY-MM-DD");
  return "-";
};

function SubscriptionRoute({
  authUser,
  user,
  isLoadingUser,
}: {
  authUser: User | null | undefined;
  user: UserModel | undefined;
  isLoadingUser: boolean;
}) {
  const [showMsg, setShowMsg] = useState(
    {} as {
      type: "success" | "warning" | "info" | "error";
      message: string;
      isShown: boolean;
    }
  );
  const [openPaymentDialog, setOpenPaymentDialog] = useState({
    isOpenDialog: false,
  } as {
    isOpenDialog: boolean;
  });

  const cancelSubscription = useCancelSubscription();
  const confirmSubscription = useConfirmSubscription();
  const createSubscription = useCreateSubscription();

  const date = moment();

  return authUser?.emailVerified && !isLoadingUser && user ? (
    <div className="SubscriptionRoute">
      {!user.subscription ? null : (
        <Card sx={{ maxWidth: 345 }}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              fontWeight="bold"
            >
              {user.subscription.type.toString()}
            </Typography>
            {user.subscription.isValid ? (
              <Typography
                gutterBottom
                variant="h6"
                color="text.primary"
                component="div"
              >
                Active to: {getDateStr(user.subscription.validTo)}
                {user.subscription.willBeRenew ? " (auto-renewal)" : ""}
              </Typography>
            ) : (
              <Typography
                gutterBottom
                variant="h6"
                color="text.secondary"
                component="div"
              >
                Expired at: {getDateStr(user.subscription.validTo)}
              </Typography>
            )}
            <Typography variant="body2" color="text.secondary">
              {user.subscription.description}
            </Typography>
            <Typography
              gutterBottom
              variant="body2"
              color="text.secondary"
              component="div"
            >
              Requests:{" "}
              {user.statistics?.requests?.[date.format("YYYY")]?.[
                date.format("MM")
              ]?.toFixed(0) || "0"}
              /{user.subscription.type.limit.toFixed(0)} (month)
            </Typography>
            <Typography
              gutterBottom
              variant="body2"
              color="text.secondary"
              component="div"
            >
              Templates:{" "}
              {Object.keys(user.templates?.map || {}).length.toFixed(0)}/
              {user.subscription.type.templateLimit.toFixed(0)}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              onClick={async () => {
                setOpenPaymentDialog({
                  isOpenDialog: true,
                });
                logEvent(FirebaseAnalytics, "view_subscriptions");
              }}
            >
              {!isMoment(user.subscription.validTo) ||
              moment().valueOf() < user.subscription.validTo.valueOf()
                ? "Change"
                : "Renew"}
            </Button>
          </CardActions>
        </Card>
      )}
      <Snackbar
        open={showMsg.isShown}
        autoHideDuration={6000}
        onClose={() => {
          setShowMsg({ ...showMsg, isShown: false });
        }}
        sx={{ width: "100%", padding: "3vmin", justifyContent: "end" }}
      >
        {
          <Alert
            onClose={() => {
              setShowMsg({ ...showMsg, isShown: false });
            }}
            sx={{ marginRight: "3vmin" }}
            severity={showMsg.type}
          >
            {showMsg.message}
          </Alert>
        }
      </Snackbar>
      <Dialog open={openPaymentDialog.isOpenDialog} maxWidth={false}>
        <DialogTitle>Select your subscription</DialogTitle>
        <DialogContent>
          {user.subscription?.isValid ? (
            <center>
              <Typography marginBottom={"10pt"} maxWidth={"400pt"}>
                When changing the subscription plan, you immediately pay the
                full cost of the new subscription plan. In this case,
                <b>
                  {" "}
                  the previous subscription will be cancelled without refund
                </b>
                . The new subscription will be valid immediately after payment.
              </Typography>
            </center>
          ) : null}
          <Grid container spacing={3}>
            {Object.keys(subscriptionsTypes)
              .map((e) => ({
                id: e,
                ...subscriptionsTypes[e],
              }))
              .filter((e) => e.isPaid)
              .map((e) => (
                <Grid item key={e.id}>
                  <Card
                    sx={{
                      width: "180pt",
                      height: "360pt",
                      background:
                        user.subscription?.isValid &&
                        e.id.toLowerCase() ===
                          user.subscription.type.name.toLowerCase()
                          ? "#0080003d"
                          : "white",
                    }}
                  >
                    <CardContent sx={{ height: "260pt" }}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        fontWeight="bold"
                      >
                        {e.id.toString().toUpperCase()}
                      </Typography>
                      <Typography gutterBottom variant="body2" component="div">
                        ${((e.price || 0) / 100)?.toFixed(2)}/month
                      </Typography>
                      {e.description.map((k, i) => (
                        <Typography
                          key={`${e.id}_subscr_descr_${i}`}
                          gutterBottom
                          variant="body2"
                          color="text.secondary"
                          component="div"
                        >
                          <CheckIcon sx={{ height: "12pt" }}></CheckIcon>
                          {k}
                        </Typography>
                      ))}
                    </CardContent>
                    <CardActions>
                      {e.plan ? (
                        e.id.toLowerCase() ===
                          user.subscription?.type.name.toLowerCase() &&
                        user.subscription?.isValid &&
                        user.subscription.willBeRenew ? (
                          <Box sx={{ width: "90%" }}>
                            <Button
                              sx={{
                                backgroundColor: "#eee",
                                color: "#2C2E2F",
                                width: "100%",
                              }}
                              onClick={async () => {
                                try {
                                  await cancelSubscription[0]({})
                                    .then(async (res) => {
                                      if (cancelSubscription[2])
                                        return Promise.reject(
                                          getErrorFromCallable(
                                            cancelSubscription[2]
                                          )
                                        );
                                      return;
                                    })
                                    .finally(() => {
                                      logEvent(
                                        FirebaseAnalytics,
                                        "cancel_subscriptions"
                                      );
                                    });
                                  setShowMsg({
                                    type: "success",
                                    message: `The subscription renewal has been cancelled.`,
                                    isShown: true,
                                  });
                                } catch (err: Error | any) {
                                  setShowMsg({
                                    type: "error",
                                    message: `${
                                      err?.message || "Unknown error"
                                    }`,
                                    isShown: true,
                                  });
                                }
                              }}
                              disabled={cancelSubscription[1]}
                            >
                              Cancel
                            </Button>
                            <Typography
                              fontSize={"8pt"}
                              fontWeight={"bold"}
                              marginTop={"3pt"}
                              color={"black"}
                            >
                              If you cancel your subscription, the funds will
                              not be refunded.
                            </Typography>
                          </Box>
                        ) : (
                          <PayPalButtons
                            style={{
                              shape: "rect",
                              color: "silver",
                              layout: "vertical",
                              label: "subscribe",
                            }}
                            createSubscription={async (data, actions) => {
                              return await createSubscription[0]({
                                planId: e.plan as string,
                              })
                                .then(async (res) => {
                                  if (createSubscription[2])
                                    return Promise.reject(
                                      getErrorFromCallable(
                                        createSubscription[2]
                                      )
                                    );
                                  return res.data.id;
                                })
                                .finally(() => {
                                  logEvent(
                                    FirebaseAnalytics,
                                    "begin_checkout" as any,
                                    {
                                      currency: "USD",
                                      value: e.price,
                                      items: [
                                        {
                                          item_id: e.id,
                                          item_name: e.plan,
                                          item_category: "subscription",
                                          item_variant: e.plan,
                                          item_brand: "IReceipt PRO",
                                          price: e.price,
                                        },
                                      ],
                                    }
                                  );
                                });
                            }}
                            onApprove={async (data, actions) => {
                              try {
                                return confirmSubscription[0]({
                                  subscriptionId: data.subscriptionID as string,
                                })
                                  .then(async (res) => {
                                    if (confirmSubscription[2])
                                      return Promise.reject(
                                        getErrorFromCallable(
                                          confirmSubscription[2]
                                        )
                                      );
                                    setShowMsg({
                                      type: "success",
                                      message: `The subscription has been issued`,
                                      isShown: true,
                                    });
                                    return;
                                  })
                                  .finally(() => {
                                    logEvent(
                                      FirebaseAnalytics,
                                      "purchase" as any,
                                      {
                                        transaction_id: data.orderID,
                                        affiliation: "PayPal",
                                        currency: "USD",
                                        value: e.price,
                                        tax: 0,
                                        shipping: 0,
                                        items: [
                                          {
                                            item_id: e.id,
                                            item_name: e.plan,
                                            item_category: "subscription",
                                            item_variant: e.price?.toString(),
                                            item_brand: "IReceipt PRO",
                                            price: e.price,
                                          },
                                        ],
                                      }
                                    );
                                  });
                              } catch (err: Error | any) {
                                setShowMsg({
                                  type: "error",
                                  message: `${err?.message || "Unknown error"}`,
                                  isShown: true,
                                });
                              }
                            }}
                            onError={async (err) => {
                              console.error(err);
                              setShowMsg({
                                type: "error",
                                message: `${err?.message || "Unknown error"}`,
                                isShown: true,
                              });
                            }}
                          ></PayPalButtons>
                        )
                      ) : null}
                    </CardActions>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenPaymentDialog({
                isOpenDialog: false,
              });
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : (
    <Loader />
  );
}

export default SubscriptionRoute;
