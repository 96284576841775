import { HttpsCallableResult } from "firebase/functions";
import { useCallableFn } from "../utils/useCallableFn";

const methodName = "v1_subscription_create" as const;

export type CreateSubscriptionResult = HttpsCallableResult<{ id: string }>;
export type CreateSubscriptionData = {
  planId: string;
};

export const useCreateSubscription = () =>
  useCallableFn<CreateSubscriptionData, CreateSubscriptionResult>(methodName);
