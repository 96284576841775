import { HttpsCallableResult } from "firebase/functions";
import { useCallableFn } from "../utils/useCallableFn";

const methodName = "v1_users_create_token" as const;

export type CreateTokenResult = HttpsCallableResult<{ id: string }>;
export type CreateTokenData = {
  id?: string;
  scope?: string[];
};

export const useCreateToken = () =>
  useCallableFn<CreateTokenData, CreateTokenResult>(methodName);
