import moment, { Moment } from 'moment-timezone';
import { SubscriptionType, subscriptionsTypes } from '../../SubscriptionType';
import { Timestamp } from 'firebase/firestore';
import { randomStr } from '../../../../utils/randomStr';

export class SubscriptionModel {
  constructor({
    id,
    type,
    description = '',
    paymentInfo = {},
    validTo,
    willBeRenew = true,
  }: {
    id: string;
    type: SubscriptionType;
    description: string;
    paymentInfo: { [key: string]: any };
    validTo: Moment;
    willBeRenew: boolean;
  }) {
    this.id = id;
    this.type = type;
    this.description = description;
    this.paymentInfo = paymentInfo;
    this.validTo = validTo;
    this.willBeRenew = willBeRenew;
  }

  id: string;

  type: SubscriptionType;

  description: string;

  paymentInfo: { [key: string]: any };

  validTo: Moment;

  willBeRenew: boolean;

  get isValid(): boolean {
    return moment().valueOf() < this.validTo.valueOf() &&
      Object.keys(subscriptionsTypes).map(e => e.toLowerCase()).includes(this.type.name.toLowerCase());
  }

  static fromJson(json: { [key: string]: any }): SubscriptionModel {
    return new SubscriptionModel({
      id: typeof json.id === 'string' ? json.id : randomStr(20),
      type: SubscriptionType.isValidString(json.type)
        ? SubscriptionType.fromString(json.type)
        : new SubscriptionType({ name: 'demo' }),
      description: typeof json.description === 'string'
        ? json.description
        : '',
      paymentInfo: (typeof json.paymentInfo === 'object' && json.paymentInfo) ? json.paymentInfo : {},
      validTo: moment((json.validTo as Timestamp).toMillis()),
      willBeRenew: !!json.willBeRenew,
    });
  }

  toJson(): { [key: string]: any } {
    return {
      id: this.id,
      type: this.type.toString(),
      description: this.description,
      paymentInfo: this.paymentInfo,
      validTo: Timestamp.fromMillis(this.validTo.valueOf()),
      willBeRenew: this.willBeRenew,
    };
  }
}
