import { DurationInputArg1, unitOfTime } from "moment-timezone";

export const subscriptionsTypes = JSON.parse(process.env.REACT_APP_SUBSCRIPTION_TYPES as string) as {
  [key: string]: {
    limit: number,
    templateLimit: number,
    isPaid: boolean,
    plan: string | null,
    price: number | null,
    frequency: {
      type: unitOfTime.DurationConstructor,
      value: DurationInputArg1,
    },
    description: string[],
  }
};

export class SubscriptionType {
  constructor({ name }: { name: string }) {
    this.name = name;
    this.limit = subscriptionsTypes[this.name].limit;
    this.templateLimit = subscriptionsTypes[this.name].templateLimit;
    this.isPaid = !!subscriptionsTypes[this.name].isPaid;
    this.plan = subscriptionsTypes[this.name].plan;
    this.price = subscriptionsTypes[this.name].price;
    this.frequency = {
      ...subscriptionsTypes[this.name].frequency,
    };
    this.description = [...subscriptionsTypes[this.name].description];
  }

  name: string;

  limit: number;

  templateLimit: number;

  isPaid: boolean;

  plan: string | null;

  price: number | null;

  frequency: {
    type: unitOfTime.DurationConstructor;
    value: DurationInputArg1;
  };

  description: string[];

  static fromString(str: string): SubscriptionType {
    const k = str.toLowerCase();
    const name = subscriptionsTypes[k] ? k : undefined;
    if (name) {
      return new SubscriptionType({ name });
    }
    throw new Error('Invalid type of the subscription.');
  }

  static isValidString(str?: string): Boolean {
    const k = str?.toLowerCase();
    const plans = Object.keys(subscriptionsTypes).map(e => e.toLowerCase())
    return plans.includes(k as string);
  }

  static fromPlan(str: string): SubscriptionType {
    const k = str.toLowerCase();
    const name = Object.keys(subscriptionsTypes)
      .find(e => subscriptionsTypes[e].plan?.toLowerCase() === k);
    if (name) {
      return new SubscriptionType({ name });
    }
    throw new Error('Invalid type of the subscription.');
  }

  static isValidPlan(str?: string): Boolean {
    const k = str?.toLowerCase();
    const name = subscriptionsTypes[k as string] ? k : undefined;
    return !!name;
  }

  toString(): string {
    return this.name.toUpperCase();
  }
}
