import { HttpsCallableResult } from "firebase/functions";
import { useCallableFn } from "../utils/useCallableFn";

const methodName = "v1_users_delete_token" as const;

export type DeleteTokenResult = HttpsCallableResult<{ id: string }>;
export type DeleteTokenData = {
  id: string;
};

export const useDeleteToken = () =>
  useCallableFn<DeleteTokenData, DeleteTokenResult>(methodName);
