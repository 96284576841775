import { HttpsCallableResult } from "firebase/functions";
import { useCallableFn } from "../utils/useCallableFn";

const methodName = "v1_users_add_domain" as const;

export type AddDomainResult = HttpsCallableResult<{ id: string }>;
export type AddDomainData = {
  regExp: string;
};

export const useAddDomain = () =>
  useCallableFn<AddDomainData, AddDomainResult>(methodName);
