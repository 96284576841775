export class PublicTemplate {
  constructor({
    id,
    name,
    ref,
    argsRef,
    width = 796,
    height = 1126,
  }: {
    id: string;
    name: string;
    ref: string;
    argsRef: string;
    width?: number;
    height?: number;
  }) {
    this.id = id;
    this.name = name;
    this.ref = ref;
    this.argsRef = argsRef;
    this.width = width;
    this.height = height;
  }

  id: string;

  name: string;

  ref: string;

  argsRef: string;

  width: number;

  height: number;

  static fromJson(id: string, json: { [key: string]: any }): PublicTemplate {
    return new PublicTemplate({
      id: id,
      name: json?.name || "",
      ref: json?.ref || "",
      argsRef: json?.argsRef || "",
      width: typeof json?.width === "number" ? json.width : 796,
      height: typeof json?.height === "number" ? json.height : 1126,
    });
  }

  toJson(): { [key: string]: any } {
    return {
      name: this.name,
      ref: this.ref,
      argsRef: this.argsRef,
      width: this.width,
      height: this.height,
    };
  }
}
