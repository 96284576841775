import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import "./DomainsRoute.css";
import Loader from "../components/Loader";
import { UserModel } from "../models/database/User";
import { useState } from "react";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useAddDomain } from "../methods/addDomain";
import { useDeleteDomain } from "../methods/deleteDomain";
import { User } from "firebase/auth";
import { getErrorFromCallable } from "../utils/getErrorFromCallable";
import FirebaseAnalytics from "../services/FirebaseAnalytics";
import { logEvent } from "firebase/analytics";

function DomainsRoute({
  authUser,
  user,
  isLoadingUser,
}: {
  authUser: User | null | undefined;
  user: UserModel | undefined;
  isLoadingUser: boolean;
}) {
  const [showMsg, setShowMsg] = useState(
    {} as {
      type: "success" | "warning" | "info" | "error";
      message: string;
      isShown: boolean;
    }
  );

  const [editDomain, setEditDomain] = useState({
    isOpenDialog: false,
  } as {
    domain?: string;
    isOpenDialog: boolean;
  });

  const addDomain = useAddDomain();
  const deleteDomain = useDeleteDomain();

  let domain: string | undefined;

  return authUser?.emailVerified && !isLoadingUser && user ? (
    <div className="DomainsRoute">
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <div />
        <Button
          sx={{ marginLeft: "auto" }}
          variant="outlined"
          color="primary"
          onClick={async () => {
            setEditDomain({
              domain: undefined,
              isOpenDialog: true,
            });
          }}
          disabled={editDomain.isOpenDialog}
        >
          <AddCircleOutlineOutlinedIcon />
        </Button>{" "}
      </Toolbar>
      {user.domains?.length === 0 ? (
        <Typography align="center" fontWeight="bold">
          You do not have a CORS policy installed, your tokens can be used on
          any site. <br />
          To set the policy, create a domain RegExp to verify the Origin header.
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell align="center">REGEXP</TableCell>
                <TableCell align="center">EDIT</TableCell>
                <TableCell align="center">REMOVE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {user.domains?.map((e, i) => (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  key={`domains_table_row_${i}`}
                >
                  <TableCell
                    align="center"
                    key={`domains_table_row_${i}_cell_id`}
                  >
                    {i}
                  </TableCell>
                  <TableCell
                    align="center"
                    key={`domains_table_row_${i}_cell_dregExp`}
                  >
                    {e.source}
                  </TableCell>
                  <TableCell align="center" key={`domains_table_row_${i}_edit`}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setEditDomain({
                          domain: e.source,
                          isOpenDialog: true,
                        });
                      }}
                      disabled={editDomain.isOpenDialog}
                      key={`domains_table_row_${i}_edit_button`}
                    >
                      <EditOutlinedIcon
                        key={`domains_table_row_${i}_edit_button_icon`}
                      />
                    </Button>
                  </TableCell>
                  <TableCell
                    align="center"
                    key={`domains_table_row_${i}_delete`}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={async () => {
                        logEvent(FirebaseAnalytics, "remove_domain");
                        await deleteDomain[0]({ regExp: e.source })
                          .then(() => {
                            if (!deleteDomain[2])
                              setShowMsg({
                                type: "success",
                                message: "Removed!",
                                isShown: true,
                              });
                            else
                              setShowMsg({
                                type: "error",
                                message: getErrorFromCallable(deleteDomain[2])
                                  .message,
                                isShown: true,
                              });
                          })
                          .catch((err: Error) => {
                            setShowMsg({
                              type: "error",
                              message: err.message || "Unknown error",
                              isShown: true,
                            });
                          });
                      }}
                      disabled={addDomain[1] || deleteDomain[1]}
                      key={`domains_table_row_${i}_delete_button`}
                    >
                      <DeleteOutlineOutlinedIcon
                        key={`domains_table_row_${i}_delete_button_icon`}
                      />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Dialog open={editDomain.isOpenDialog}>
        <DialogTitle>
          {typeof editDomain.domain === "string" ? "Edit" : "Add"} Domain
        </DialogTitle>
        <DialogContent sx={{ width: "400pt", maxWidth: "100%" }}>
          <FormControl fullWidth sx={{ marginTop: "1em" }}>
            <Typography>
              Example: <var>https?:\/\/(\w+\.)*ireceipt\.pro$</var>
            </Typography>
            <TextField
              label="RegExp"
              defaultValue={editDomain.domain || ""}
              rows={1}
              onChange={(v) => {
                if (typeof v.target.value === "string") domain = v.target.value;
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setEditDomain({
                domain: undefined,
                isOpenDialog: false,
              });
            }}
            disabled={addDomain[1] || deleteDomain[1]}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (typeof domain !== "string") {
                setShowMsg({
                  type: "error",
                  message: "Invalid RegExp",
                  isShown: true,
                });
                return;
              }
              try {
                new RegExp(domain);
              } catch (err) {
                setShowMsg({
                  type: "error",
                  message: `Invalid RegExp: ${err}`,
                  isShown: true,
                });
                return;
              }
              if (editDomain.domain) {
                logEvent(FirebaseAnalytics, "edit_domain");
                deleteDomain[0]({ regExp: editDomain.domain })
                  .then(() => {
                    if (deleteDomain[2])
                      return Promise.reject(
                        getErrorFromCallable(deleteDomain[2])
                      );
                    return addDomain[0]({ regExp: domain as string });
                  })
                  .then(() => {
                    if (addDomain[2])
                      return Promise.reject(getErrorFromCallable(addDomain[2]));
                    setEditDomain({
                      domain: undefined,
                      isOpenDialog: false,
                    });
                    setShowMsg({
                      type: "success",
                      message: "Updated!",
                      isShown: true,
                    });
                  })
                  .catch((err: Error) => {
                    setShowMsg({
                      type: "error",
                      message: err.message || "Unknown error",
                      isShown: true,
                    });
                  });
              } else {
                logEvent(FirebaseAnalytics, "add_domain");
                addDomain[0]({ regExp: domain })
                  .then(() => {
                    if (addDomain[2])
                      return Promise.reject(getErrorFromCallable(addDomain[2]));
                    setEditDomain({
                      domain: undefined,
                      isOpenDialog: false,
                    });
                    setShowMsg({
                      type: "success",
                      message: "Created!",
                      isShown: true,
                    });
                  })
                  .catch((err: Error) => {
                    setShowMsg({
                      type: "error",
                      message: err.message || "Unknown error",
                      isShown: true,
                    });
                  });
              }
            }}
            disabled={addDomain[1] || deleteDomain[1]}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showMsg.isShown}
        autoHideDuration={6000}
        onClose={() => {
          setShowMsg({ ...showMsg, isShown: false });
        }}
        sx={{ width: "100%", padding: "3vmin", justifyContent: "end" }}
      >
        {
          <Alert
            onClose={() => {
              setShowMsg({ ...showMsg, isShown: false });
            }}
            sx={{ marginRight: "3vmin" }}
            severity={showMsg.type}
          >
            {showMsg.message}
          </Alert>
        }
      </Snackbar>
    </div>
  ) : (
    <Loader />
  );
}

export default DomainsRoute;
