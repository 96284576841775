import {
  DocumentReference,
  QueryDocumentSnapshot,
  DocumentSnapshot,
  Transaction,
  collection,
  doc,
  getDoc,
  setDoc,
} from 'firebase/firestore';
import FirebaseFirestore from '../../../services/FirebaseFirestore';
import { PublicTemplate } from '../PublicTemplate';

export class PublicTemplatesModel {
  constructor(templates: { [key: string]: PublicTemplate }) {
    this.map = templates || {};
  }

  static id = 'public_templates'

  id = PublicTemplatesModel.id

  map: { [key: string]: PublicTemplate };

  static fromJson(json: { [key: string]: any }): PublicTemplatesModel {
    return new PublicTemplatesModel(
      typeof json === 'object' ? Object.keys(json).reduce((acc, val) => {
        acc[val] = PublicTemplate.fromJson(val, json[val]);
        return acc;
      }, {} as { [key: string]: PublicTemplate }) : {}
    );
  }

  toJson(): { [key: string]: any } {
    return Object.keys(this.map).reduce((acc, val) => {
      acc[val] = this.map[val].toJson();
      return acc;
    }, {} as { [key: string]: any });
  }

  static parent = collection(FirebaseFirestore, '_internal_').withConverter<PublicTemplatesModel>({
    toFirestore: (doc: PublicTemplatesModel) => doc.toJson(),
    fromFirestore: (snapshot: QueryDocumentSnapshot) =>
      PublicTemplatesModel.fromJson(snapshot.data()),
  });

  static ref = doc(this.parent, PublicTemplatesModel.id);

  ref = (): DocumentReference<PublicTemplatesModel> => {
    return doc(PublicTemplatesModel.parent, this.id);
  };

  load = (transaction?: Transaction): Promise<DocumentSnapshot<PublicTemplatesModel>> =>
    transaction instanceof Transaction
      ? transaction.get(this.ref())
      : getDoc(this.ref());

  save = (transaction?: Transaction): Promise<void | Transaction> =>
    transaction instanceof Transaction
      ? Promise.resolve(transaction.set(this.ref(), this))
      : setDoc(this.ref(), this);
}
