export class CacheDatastore<T>{
  constructor() {
    this.datastore = new Map<string, T>();
  }

  private datastore: Map<string, T>;

  public get(key: string): T | undefined {
    return this.datastore.get(key);
  }

  public set(key: string, data: T): T {
    this.datastore.set(key, data);
    return this.datastore.get(key) as T;
  }

  public remove(key: string): void {
    this.datastore.delete(key);
    return;
  }

  public clear(): void {
    this.datastore.clear();
    return;
  }
}
