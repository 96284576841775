import { Box, FilledInput, List, ListItem, Typography } from "@mui/material";
import "./FaqRoute.css";
import Mustache from "mustache";

function FaqRoute() {
  const updateResult = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    prefix: string
  ) => {
    try {
      event.preventDefault();
      const htmlEl = document.getElementById(`${prefix}_html`);
      const jsonEl = document.getElementById(`${prefix}_json`);
      const resEl = document.getElementById(`${prefix}_result`);
      if (htmlEl && jsonEl && resEl) {
        const resHtml = Mustache.render(
          (htmlEl as any).value,
          JSON.parse((jsonEl as any).value)
        );
        (resEl as any).value = resHtml;
      }
    } catch (err) {
      console.debug(err);
    }
  };

  return (
    <div className="FaqRoute">
      <List>
        <ListItem component={"a"} href="#how_to_use_api">
          How to use the API?
        </ListItem>
        <ListItem component={"a"} href="#how_to_create_your_own_template">
          How do you create your own templates?
        </ListItem>
      </List>
      <Box sx={{ marginTop: "12pt" }}></Box>
      <Typography variant="h5" id="how_to_use_api" textAlign={"center"}>
        How to use the API?
      </Typography>
      <Box sx={{ marginTop: "12pt" }}></Box>
      <Typography variant="body1">
        First of all, you will need to create an access token to use the API.
        You can do this on the{" "}
        <a href="/tokens" rel="noreferrer" target="_blank">
          TOKENS
        </a>{" "}
        page.
      </Typography>
      <div style={{ textAlign: "center" }}>
        <Box
          sx={{ width: "80%" }}
          borderRadius={"12pt"}
          border={"Window"}
          component={"img"}
          src="/assets/images/screenshots/tokens.jpg"
          alt="Tokens"
        ></Box>
      </div>
      <Typography variant="body1">
        After you have created an access token, you can restrict the use of your
        tokens on sites using the CORS policy. To do this, you need to specify a
        regular expression describing the address of your site, for example, it
        can be <i>{`^https:\\/\\/(\\w+\\.)yoursite\\.com`}</i>. You can do this
        on the{" "}
        <a href="/domains" rel="noreferrer" target="_blank">
          DOMAINS
        </a>{" "}
        page.
      </Typography>
      <div style={{ textAlign: "center" }}>
        <Box
          sx={{ width: "80%" }}
          borderRadius={"12pt"}
          component={"img"}
          src="/assets/images/screenshots/domains.jpg"
          alt="Domains"
        ></Box>
      </div>
      <Typography variant="body1">
        To create a PDF file or JPEG image, you need to know the template ID and
        pass your own arguments to the template. You can see the template ID and
        the argument variant and how to conduct experiments on how arguments
        affect the template in the{" "}
        <a href="/sandbox" rel="noreferrer" target="_blank">
          SANDBOX
        </a>{" "}
        . You can also experiment with formats.
      </Typography>
      <div style={{ textAlign: "center" }}>
        <Box
          sx={{ width: "80%" }}
          borderRadius={"12pt"}
          component={"img"}
          src="/assets/images/screenshots/sandbox.jpg"
          alt="Sandbox"
        ></Box>
      </div>
      <Typography variant="body1">
        When you are done with the experiments, you can go to the{" "}
        <a href="/" rel="noreferrer" target="_blank">
          API
        </a>{" "}
        page and check the selected template and arguments in the real API (all
        requests from this tab are subject to the general restrictions of your
        tariff plan).
      </Typography>
      <div style={{ textAlign: "center" }}>
        <Box
          sx={{ width: "80%" }}
          borderRadius={"12pt"}
          component={"img"}
          src="/assets/images/screenshots/api.jpg"
          alt="API"
        ></Box>
      </div>
      <Typography variant="body1">
        If you want to create your own template, you can do it with a paid
        subscription. This is described in detail in the relevant section.
      </Typography>
      <Typography variant="body1">
        You can track query statistics on the{" "}
        <a href="/statistics" rel="noreferrer" target="_blank">
          STATISTICS
        </a>{" "}
        page. Change the tariff plan on the{" "}
        <a href="/subscription" rel="noreferrer" target="_blank">
          SUBSCRIPTION
        </a>{" "}
        page. It is important that the tariff plan is changed immediately, the
        previous tariff plan is canceled without refund. When changing, you pay
        the full cost of the new tariff plan. Requests used under the previous
        tariff plan are transferred to the new tariff plan.
      </Typography>
      <div style={{ textAlign: "center" }}>
        <Box
          sx={{ width: "80%" }}
          borderRadius={"12pt"}
          component={"img"}
          src="/assets/images/screenshots/statistics.jpg"
          alt="Statistics"
        ></Box>
      </div>
      <Typography variant="body1">
        For example, your tariff plan includes 1000 requests/month and you have
        used the limit. You can switch to a tariff plan with 2000
        requests/month, while you pay the cost of the new tariff plan in full.
        As a result, you will have an active tariff plan with 2000
        requests/month and 1000 available requests (1000 you spent under the
        previous tariff plan). Refunds for the previous tariff plan are not
        made, they also do not count towards the new tariff plan.
      </Typography>
      <div style={{ textAlign: "center" }}>
        <Box
          sx={{ width: "80%" }}
          borderRadius={"12pt"}
          component={"img"}
          src="/assets/images/screenshots/subscription.jpg"
          alt="Subscription"
        ></Box>
      </div>
      <Typography variant="body1">
        The subscription payment takes place once a month from the moment of
        activation, while the limits are updated once a month on the first day
        of the month. Thus, if you paid the tariff plan for 2000 requests/month
        on February 16, then you had 2000 requests in February and 2000 requests
        in March. On March 16, the subscription will be automatically renewed,
        if you canceled the subscription renewal or the subscription was not
        renewed for other reasons, then from March 16 you will not be able to
        use the remaining requests until a new subscription is activated or the
        current one is renewed.
      </Typography>
      <Typography variant="body1">
        The tariff plan is changed to a tariff plan with fewer requests in the
        same way. So if you switch from a tariff plan of 2000 requests per month
        to a tariff plan of 1000 requests per month and you have used up 900
        requests, then the remainder of the available requests in the current
        month will be 100. If you have spent more than 1000 requests, then this
        month you will not be able to use the API unless you switch to a tariff
        plan with a large number of requests. But next month you will have 1000
        requests available (according to your tariff plan) for the duration of
        the subscription.
      </Typography>
      <Box sx={{ marginTop: "12pt" }}></Box>
      <Typography
        variant="h5"
        id="how_to_create_your_own_template"
        textAlign={"center"}
      >
        How do you create your own templates?
      </Typography>
      <Box sx={{ marginTop: "12pt" }}></Box>
      <Typography variant="body1">
        You can create your own template on the{" "}
        <a href="/templates" rel="noreferrer" target="_blank">
          TEMPLATES
        </a>{" "}
        page (a paid subscription is required). In addition to the html code,
        you also need to specify the template metadata (name, example arguments,
        and size). You can do this by clicking on the settings button next to
        the template ID.
      </Typography>
      <div style={{ textAlign: "center" }}>
        <Box
          sx={{ width: "80%" }}
          borderRadius={"12pt"}
          component={"img"}
          src="/assets/images/screenshots/templates.jpg"
          alt="Templates"
        ></Box>
      </div>
      <Typography variant="body1">
        We use{" "}
        <a href="https://mustache.github.io/" rel="noreferrer" target="_blank">
          mustache
        </a>{" "}
        templates to generate html layout.
      </Typography>
      <Typography variant="body1">
        You can use the official mustache documentation as a manual for writing
        your own templates. Templates support the html layout language in the
        form of simple html and css and do not support javascript and links to
        external sources. This means that to embed an image, it must be base64
        encoded and passed as part of a template or argument.
      </Typography>
      <Box sx={{ marginTop: "12pt" }}></Box>
      <Typography variant="h6" id="common_variables" textAlign={"center"}>
        Common variables
      </Typography>
      <Box sx={{ marginTop: "12pt" }}></Box>
      <Typography variant="body1">
        To display regular variables, they must be wrapped in double curly
        brackets <i>{`{{ variable }}`}</i>. Regular variables are converted to a
        string by the <i>{`.toString()`}</i> method.
      </Typography>
      <Typography variant="body1">Template:</Typography>
      <FilledInput
        id="common_variables_html"
        defaultValue={`<html>\n\t<body>Hello {{ planet }}</body>\n</html>`}
        multiline={true}
        sx={{ minWidth: "400pt", width: "50%" }}
        onChange={(event) => updateResult(event, "common_variables")}
      ></FilledInput>
      <Typography variant="body1">Arguments:</Typography>
      <FilledInput
        id="common_variables_json"
        defaultValue={JSON.stringify({ planet: "Earth" }, undefined, "\t")}
        multiline={true}
        sx={{ minWidth: "400pt", width: "50%" }}
        onChange={(event) => updateResult(event, "common_variables")}
      ></FilledInput>
      <Typography variant="body1">Result:</Typography>
      <FilledInput
        id="common_variables_result"
        defaultValue={`<html>\n\t<body>Hello Earth</body>\n</html>`}
        multiline={true}
        sx={{ minWidth: "400pt", width: "50%" }}
      ></FilledInput>
      <Box sx={{ marginTop: "12pt" }}></Box>
      <Typography variant="h6" id="conditions" textAlign={"center"}>
        Conditions
      </Typography>
      <Box sx={{ marginTop: "12pt" }}></Box>
      <Typography variant="body1">
        Text wrapped in <i>{`{{#variable}} {{/variable}}`}</i> will be displayed
        if the condition is true, and text wrapped in{" "}
        <i>{`{{^variable}} {{/variable}}`}</i> will be displayed if the
        condition is false.
      </Typography>
      <Typography variant="body1">Template:</Typography>
      <FilledInput
        id="conditions_html"
        defaultValue={`<html>\n\t<body>{{#myCondition}}Hello{{/myCondition}}{{^myCondition}}Bye{{/myCondition}} {{ planet }}</body>\n</html>`}
        multiline={true}
        sx={{ minWidth: "400pt", width: "50%" }}
        onChange={(event) => updateResult(event, "conditions")}
      ></FilledInput>
      <Typography variant="body1">Arguments:</Typography>
      <FilledInput
        id="conditions_json"
        defaultValue={JSON.stringify(
          { planet: "Earth", myCondition: false },
          undefined,
          "\t"
        )}
        multiline={true}
        sx={{ minWidth: "400pt", width: "50%" }}
        onChange={(event) => updateResult(event, "conditions")}
      ></FilledInput>
      <Typography variant="body1">Result:</Typography>
      <FilledInput
        id="conditions_result"
        defaultValue={`<html>\n\t<body>Bye Earth</body>\n</html>`}
        multiline={true}
        sx={{ minWidth: "400pt", width: "50%" }}
      ></FilledInput>
      <Box sx={{ marginTop: "12pt" }}></Box>
      <Typography variant="h6" id="objects" textAlign={"center"}>
        Objects
      </Typography>
      <Box sx={{ marginTop: "12pt" }}></Box>
      <Typography variant="body1">
        The fields of the object are accessed through the point{" "}
        <i>{`{{ planet.name }}`}</i>
      </Typography>
      <Typography variant="body1">Template:</Typography>
      <FilledInput
        id="objects_html"
        defaultValue={`<html>\n\t<body>Hello {{ planet.name }}</body>\n</html>`}
        multiline={true}
        sx={{ minWidth: "400pt", width: "50%" }}
        onChange={(event) => updateResult(event, "objects")}
      ></FilledInput>
      <Typography variant="body1">Arguments:</Typography>
      <FilledInput
        id="objects_json"
        defaultValue={JSON.stringify(
          { planet: { name: "Earth" } },
          undefined,
          "\t"
        )}
        multiline={true}
        sx={{ minWidth: "400pt", width: "50%" }}
        onChange={(event) => updateResult(event, "objects")}
      ></FilledInput>
      <Typography variant="body1">Result:</Typography>
      <FilledInput
        id="objects_result"
        defaultValue={`<html>\n\t<body>Hello Earth</body>\n</html>`}
        multiline={true}
        sx={{ minWidth: "400pt", width: "50%" }}
      ></FilledInput>
      <Box sx={{ marginTop: "12pt" }}></Box>
      <Typography variant="h6" id="arrays" textAlign={"center"}>
        Arrays
      </Typography>
      <Box sx={{ marginTop: "12pt" }}></Box>
      <Typography variant="body1">
        The array elements are accessed by wrapping the code in{" "}
        <i>{`{{#users}}{{/users}}`}</i> and using the elements. A direct
        reference to an element inside this structure is made using the dot{" "}
        <i>{`{{.}}`}</i>, and a reference to the fields of objects in accordance
        with the syntax of objects <i>{`{{#users2}} {{ name }} {{/users2}}`}</i>
        . You can also use direct access by the element number in the array{" "}
        <i>{`{{users.1}}`}</i>.
      </Typography>
      <Typography variant="body1">Template:</Typography>
      <FilledInput
        id="arrays_html"
        defaultValue={`<html>\n\t<body>\n\t\t{{#users}}<div>Hello {{.}}</div>{{/users}}\n\t\t{{#users2}}<div><b>Hello {{name}}</b></div>{{/users2}}\n\t\t<div><i>Hello {{users.1}}</i></div>\n\t</body>\n</html>`}
        multiline={true}
        sx={{ minWidth: "400pt", width: "50%" }}
        onChange={(event) => updateResult(event, "arrays")}
      ></FilledInput>
      <Typography variant="body1">Arguments:</Typography>
      <FilledInput
        id="arrays_json"
        defaultValue={JSON.stringify(
          {
            users: ["Hans", "Fritz", "Geraldine"],
            users2: [
              {
                name: "Sven",
              },
              {
                name: "Vitold",
              },
            ],
          },
          undefined,
          "\t"
        )}
        multiline={true}
        sx={{ minWidth: "400pt", width: "50%" }}
        onChange={(event) => updateResult(event, "arrays")}
      ></FilledInput>
      <Typography variant="body1">Result:</Typography>
      <FilledInput
        id="arrays_result"
        defaultValue={`<html>\n\t<body>\n\t\t<div>Hello Hans</div>\n\t\t<div>Hello Fritz</div>\n\t\t<div>Hello Geraldine</div>\n\t\t<div><b>Hello Sven</b></div>\n\t\t<div><b>Hello Vitold</b></div>\n\t\t<div><i>Hello Fritz</i></div>\n\t</body>\n</html>`}
        multiline={true}
        sx={{ minWidth: "400pt", width: "50%" }}
      ></FilledInput>
      <Box sx={{ marginBottom: "10vmin" }} />
    </div>
  );
}

export default FaqRoute;
