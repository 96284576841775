import { PublicTemplate } from '../../PublicTemplate';

export class PrivateTemplatesModel {
  constructor(templates: { [key: string]: PublicTemplate }) {
    this.map = templates || {};
  }

  map: { [key: string]: PublicTemplate };

  static fromJson(json: { [key: string]: any }): PrivateTemplatesModel {
    return new PrivateTemplatesModel(
      typeof json === 'object'
        ? Object.keys(json).reduce(
            (acc, val) => {
              acc[val] = PublicTemplate.fromJson(val, json[val]);
              return acc;
            },
            {} as { [key: string]: PublicTemplate }
          )
        : {}
    );
  }

  toJson(): { [key: string]: any } {
    return Object.keys(this.map).reduce(
      (acc, val) => {
        acc[val] = this.map[val].toJson();
        return acc;
      },
      {} as { [key: string]: any }
    );
  }
}
