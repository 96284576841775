import moment, { Moment } from 'moment-timezone';
import { Timestamp } from 'firebase/firestore';
import { randomStr } from '../../../../utils/randomStr';

export class TokenModel {
  constructor({
    id,
    token,
    description,
    lastRequest,
    validTo,
  }: {
    id: string;
    description?: string;
    token: string;
    lastRequest?: Moment;
    validTo?: Moment;
  }) {
    this.id = id;
    this.token = token;
    this.description = description || '';
    this.lastRequest = lastRequest ? lastRequest : moment(0);
    this.validTo = validTo ? validTo : moment(0).add(1, 'year');
  }

  id: string;

  /**
   * Base 64 from `${userID}${tokenID}`
   */
  token: string;

  description: string;

  lastRequest: Moment;

  validTo: Moment;

  static fromJson(json: { [key: string]: any }): TokenModel {
    return new TokenModel({
      id: typeof json.id === 'string' ? json.id : randomStr(20),
      token: typeof json.token === 'string' ? json.token : '',
      description: typeof json.description === 'string' ? json.description : '',
      lastRequest: json.lastRequest instanceof Timestamp ? moment(json.lastRequest.toMillis()) : moment(0),
      validTo: json.validTo instanceof Timestamp ? moment(json.validTo.toMillis()) : moment(0).add(1, 'year'),
    });
  }

  toJson(): { [key: string]: any } {
    return {
      id: this.id,
      token: this.token,
      description: this.description,
      lastRequest: Timestamp.fromMillis(this.lastRequest.valueOf()),
      validTo: Timestamp.fromMillis(this.validTo.valueOf()),
    };
  }
}
