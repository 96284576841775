import { useDocumentData } from "react-firebase-hooks/firestore";
import BaseScreen from "./screens/BaseScreen";
import MaintenanceScreen from "./screens/MaintenanceScreen";
import "./App.css";
import { InternalConfigModel } from "./models/database/InternalConfig";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

function App() {
  const [configData] = useDocumentData(InternalConfigModel.ref);
  return configData?.maintenanceMode ? (
    <MaintenanceScreen message={configData?.maintenanceMessage} />
  ) : (
    <PayPalScriptProvider
      options={{
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID || "test",
        commit: true,
        components: [
          "buttons",
          "marks",
          "funding-eligibility",
          "hosted-fields",
          "applepay",
        ],
        currency: "USD",
        disableFunding: ["credit"],
        intent: "subscription",
        vault: true,
        locale: "en_US",
        dataPageType: "product-listing",
      }}
    >
      <BaseScreen />
    </PayPalScriptProvider>
  );
}

export default App;
