import { HttpsCallableResult } from "firebase/functions";
import { useCallableFn } from "../utils/useCallableFn";

const methodName = "v1_upsert_template" as const;

export type UpsertTemplateResult = HttpsCallableResult<{ id: string }>;
export type UpsertTemplateData = {
  id: string;
  name: string;
  html: string;
  arguments: { [key: string]: any };
  width: number;
  height: number;
};

export const useUpsertTemplate = () =>
  useCallableFn<UpsertTemplateData, UpsertTemplateResult>(methodName);
