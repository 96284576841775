import {
  Alert,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import "./SandboxRoute.css";
import Loader from "../components/Loader";
import { User, sendEmailVerification } from "firebase/auth";
import { UserModel } from "../models/database";
import { useState, useEffect, useCallback } from "react";
import Mustache from "mustache";
import FirebaseStorage from "../services/FirebaseStorage";
import { ref, getBytes } from "firebase/storage";
import { getDoc } from "firebase/firestore";
import { CacheDatastore } from "../services/CacheDatastore";
import { PublicTemplatesModel } from "../models/database/PublicTemplates";
import { viewPorts } from "../models/database/viewports";
import ReplayIcon from "@mui/icons-material/Replay";
import { PrivateTemplatesModel } from "../models/database/User/PrivateTemplates";
import { PublicTemplate } from "../models/database/PublicTemplate";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { errorMessagesMap } from "../services/FirebaseAuth";

interface CacheData {
  name: string;
  box: {
    width: number;
    height: number;
  };
  template: string;
  args: string;
  isLoaded: boolean;
  isPublic: boolean;
}
const cacheDatastore = new CacheDatastore<CacheData>();
const templatesCacheDatastore = new CacheDatastore<{
  [key: string]: PublicTemplate;
}>();

const getTemplatesData = async (
  userTemplates: UserModel["templates"] = new PrivateTemplatesModel({}),
  forceReload = false
): Promise<{ [key: string]: PublicTemplate }> => {
  const cachedData = templatesCacheDatastore.get("templates");
  if (cachedData && Object.keys(cachedData || {}).length && !forceReload)
    return cachedData;
  const doc = await getDoc(PublicTemplatesModel.ref);
  const templates = doc.exists() ? doc.data() : new PublicTemplatesModel({});
  const allTEmplates: { [key: string]: PublicTemplate } = {};
  Object.keys(templates.map).forEach((k) => {
    allTEmplates[`public_${k}`] = templates.map[k];
  });
  Object.keys(userTemplates.map).forEach((k) => {
    allTEmplates[`private_${k}`] = userTemplates.map[k];
  });
  return templatesCacheDatastore.set("templates", allTEmplates);
};

const getTemplateData = async (
  templateId: string,
  templatesData: { [key: string]: PublicTemplate },
  forceReload: boolean = false
): Promise<CacheData | undefined> => {
  const cachedData = cacheDatastore.get(templateId);
  if (cachedData?.template?.length && !forceReload) return cachedData;
  const template = templatesData?.[templateId];
  if (!template) return;
  const htmlFileRef = ref(FirebaseStorage, `${template.ref}`);
  const htmlBuf = await getBytes(htmlFileRef);
  let decoder = new TextDecoder();
  const html = decoder.decode(htmlBuf);
  const jsonFileRef = ref(FirebaseStorage, `${template.argsRef}`);
  const jsonBuf = await getBytes(jsonFileRef);
  decoder = new TextDecoder();
  let str = decoder.decode(jsonBuf);
  try {
    const json = JSON.parse(str);
    if (json) str = JSON.stringify(json, undefined, "\t");
  } catch (err) {
    console.warn(err);
  }
  return cacheDatastore.set(templateId, {
    name: template.name,
    box: {
      width: template.width,
      height: template.height,
    },
    template: html,
    args: str,
    isLoaded: true,
    isPublic: true,
  });
};

function SandboxRoute({
  authUser,
  user,
  isLoadingUser,
}: {
  authUser: User | null | undefined;
  user: UserModel | undefined;
  isLoadingUser: boolean;
}) {
  const [templateId, setTemplateId] = useState("");
  const defaultTemplatesState =
    templatesCacheDatastore.get("templates") ||
    templatesCacheDatastore.set("templates", {});
  const [isRunOperation, setRunOperation] = useState(false);
  const [showMsg, setShowMsg] = useState(
    {} as {
      type: "success" | "warning" | "info" | "error";
      message: string;
      isShown: boolean;
    }
  );

  const [templatesData, setTemplatesDataRaw] = useState(defaultTemplatesState);
  const setTemplatesData = useCallback(
    (data: { [key: string]: PublicTemplate }) => {
      templatesCacheDatastore.set("templates", data);
      return setTemplatesDataRaw(data);
    },
    []
  );

  const defaultState = templateId
    ? cacheDatastore.get(templateId) ||
    cacheDatastore.set(templateId, {
      box:
        templatesData?.[templateId]?.width &&
          templatesData?.[templateId]?.height
          ? {
            width: templatesData[templateId].width,
            height: templatesData[templateId].height,
          }
          : {
            width: 796,
            height: 1126,
          },
      template: "",
      args: "{}",
      name: "",
      isLoaded: false,
      isPublic: true,
    })
    : {
      box: {
        width: 796,
        height: 1126,
      },
      template: "",
      args: "{}",
      name: "New Template",
      isLoaded: false,
      isPublic: true,
    };

  const [templateData, setTemplateDataRaw] = useState(defaultState);
  const setTemplateData = useCallback(
    (data: CacheData) => {
      cacheDatastore.set(templateId, data);
      setTemplateDataRaw(data);
    },
    [templateId]
  );

  useEffect(() => {
    (async () => {
      if (templateId !== "") {
        setShowMsg({
          type: "info",
          message: "Loading...",
          isShown: true,
        });
        const data = await getTemplateData(templateId, templatesData);
        setShowMsg({
          type: "info",
          message: "",
          isShown: false,
        });
        if (data) setTemplateData(data);
      }
    })();
  }, [templateId, templatesData, setTemplateData]);

  useEffect(() => {
    (async () => {
      if (!isLoadingUser) {
        const data = await getTemplatesData(user?.templates, true);
        setTemplatesData(data);
        if (templateId === "") {
          const firstTemplateId = Object.entries(data || {})
            .sort((a, b) => {
              if (a[1].name === b[1].name) return 0;
              return a[1].name > b[1].name ? 1 : -1;
            })[0][0];
          if (firstTemplateId) setTemplateId(firstTemplateId);
        }
      }
    })();
  }, [templateId, setTemplatesData, user?.templates, isLoadingUser]);

  const isValidJson = (s: string): { [key: string]: any } | undefined => {
    try {
      return JSON.parse(s);
    } catch (err) {
      console.warn(err);
      return undefined;
    }
  };

  const safeMustacheRender = (s: string, a: { [key: string]: any }): string => {
    try {
      return Mustache.render(s, a);
    } catch (err) {
      console.warn(err);
      return "<html></html>";
    }
  };

  const parsedArgs = isValidJson(templateData.args);

  const isContentLoaded = !isLoadingUser && templateData?.isLoaded;

  const isPrivateTemplate = /^private_/.test(templateId);

  return isContentLoaded ? (
    <div className="SandboxRoute">
      {authUser?.emailVerified ? null : (
        <Typography textAlign={"center"} style={{ marginBottom: "5vmin" }}>
          {authUser
            ? "Confirm your email by clicking on the link from your email to see private templates."
            : "Log in or register to see private templates."}
          {authUser ? (
            <Typography
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                sendEmailVerification(authUser, {
                  url: `${window.location.origin}`,
                })
                  .then(() => {
                    setShowMsg({
                      type: "success",
                      message: `The letter has been sent to ${authUser.email}`,
                      isShown: true,
                    });
                  })
                  .catch((err) => {
                    if (errorMessagesMap[err.code]) {
                      setShowMsg({
                        type: "error",
                        message: `${errorMessagesMap[err.code]}`,
                        isShown: true,
                      });
                    } else {
                      setShowMsg({
                        type: "error",
                        message: `${err?.message}`,
                        isShown: true,
                      });
                    }
                  });
              }}
            >
              (resend confirmation email)
            </Typography>
          ) : null}
        </Typography>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl
            sx={{ width: "50%", maxWidth: "400pt", marginTop: "12pt" }}
          >
            <InputLabel id="select-template-label">Template</InputLabel>
            <Select
              sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
              labelId="select-template-label"
              id="select-template-select"
              value={
                Object.keys(templatesData || {}).includes(templateId)
                  ? templateId
                  : ""
              }
              label={"Template"}
              onChange={(e) => {
                setTemplateId(e.target.value);
              }}
            >
              {Object.entries(templatesData || {})
                .sort((a, b) => {
                  if (a[1].name === b[1].name) return 0;
                  return a[1].name > b[1].name ? 1 : -1;
                })
                .map(([k, v]) => (
                  <MenuItem
                    sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    key={`menu_item_${k}`}
                    value={k}
                  >
                    {v.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Typography marginY={"auto"}>
              ID:{" "}
              <b>
                {templateId.replace(/^(private_|public_)/, "")} (
                {isPrivateTemplate ? "private" : "public"})
              </b>
            </Typography>
            <IconButton
              onClick={() => {
                setRunOperation(true);
                (async () => {
                  const dt = await getTemplatesData(user?.templates, true);
                  if (dt) setTemplatesData(dt);
                  const dt2 = await getTemplateData(templateId, dt, true);
                  if (dt2) setTemplateData(dt2);
                  if (templateId === "") {
                    const firstTemplateId = Object.entries(dt || {})
                      .sort((a, b) => {
                        if (a[1].name === b[1].name) return 0;
                        return a[1].name > b[1].name ? 1 : -1;
                      })[0][0];
                    if (firstTemplateId) setTemplateId(firstTemplateId);
                  }
                })()
                  .then(() => {
                    setShowMsg({
                      isShown: true,
                      type: "success",
                      message:
                        "The template data was downloaded from the server.",
                    });
                  })
                  .catch((err) => {
                    setShowMsg({
                      isShown: true,
                      type: "error",
                      message: `${err?.message}`,
                    });
                  })
                  .finally(() => {
                    setRunOperation(false);
                  });
              }}
              disabled={isRunOperation}
            >
              <ReplayIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid item xs={12}>
            <TextField
              sx={{ marginTop: "12pt" }}
              inputMode="numeric"
              id="box-size-width"
              label="Width"
              variant="outlined"
              value={templateData.box.width || ""}
              onChange={({ target }) => {
                try {
                  if (target.value === "") {
                    setTemplateData({
                      ...templateData,
                      box: {
                        ...templateData.box,
                        width: 0,
                      },
                    });
                    return;
                  }
                  const val = Number.parseInt(target.value);
                  if (Number.isFinite(val) && val !== templateData.box.width)
                    setTemplateData({
                      ...templateData,
                      box: {
                        ...templateData.box,
                        width: val,
                      },
                    });
                } catch (err) {
                  console.warn(err);
                }
              }}
            />
            <TextField
              sx={{ marginTop: "12pt" }}
              inputMode="numeric"
              id="box-size-height"
              label="Height"
              variant="outlined"
              value={templateData.box.height || ""}
              onChange={({ target }) => {
                try {
                  if (target.value === "") {
                    setTemplateData({
                      ...templateData,
                      box: {
                        ...templateData.box,
                        height: 0,
                      },
                    });
                    return;
                  }
                  const val = Number.parseInt(target.value);
                  if (Number.isFinite(val) && val !== templateData.box.height)
                    setTemplateData({
                      ...templateData,
                      box: {
                        ...templateData.box,
                        height: val,
                      },
                    });
                } catch (err) {
                  console.warn(err);
                }
              }}
            />
            <FormControl sx={{ width: "100pt", marginTop: "12pt" }}>
              <InputLabel id="select-format-label">Format</InputLabel>
              <Select
                sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                labelId="select-format-label"
                id="select-format-select"
                label={"Format"}
                value={
                  Object.keys(viewPorts).find(
                    (e) =>
                      viewPorts[e as keyof typeof viewPorts].width ===
                      templateData.box.width &&
                      viewPorts[e as keyof typeof viewPorts].height ===
                      templateData.box.height
                  ) || ""
                }
                onChange={(e) => {
                  if (e.target.value) {
                    const viewport =
                      viewPorts[e.target.value as keyof typeof viewPorts];
                    if (viewport)
                      setTemplateData({
                        ...templateData,
                        box: {
                          width: viewport.width,
                          height: viewport.height,
                        },
                      });
                  }
                }}
              >
                {Object.keys(viewPorts).map((k) => (
                  <MenuItem
                    sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    key={`menu_item_viewports_${k}`}
                    value={k}
                  >
                    {k.toUpperCase().replace(/-/gi, " ")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} marginTop={"12pt"}>
            <Paper variant="outlined">
              <InputLabel
                sx={{
                  position: "absolute",
                  marginX: "3pt",
                  marginY: "-9pt",
                  zIndex: 1,
                  backgroundColor: "white",
                  paddingX: "3pt",
                  paddingY: "0pt",
                }}
              >
                Arguments
              </InputLabel>
              <AceEditor
                placeholder="{}"
                mode="json"
                theme="github"
                onChange={(val) => {
                  try {
                    setTemplateData({
                      ...templateData,
                      args: val,
                    });
                  } catch (err) {
                    console.warn(err);
                  }
                }}
                value={templateData.args}
                name="sndbx-template-arguments"
                setOptions={{
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: true,
                  enableSnippets: false,
                  showLineNumbers: false,
                  tabSize: 2,
                  useWorker: false,
                }}
                editorProps={{ $blockScrolling: true }}
                fontSize={14}
                showPrintMargin={true}
                showGutter={false}
                highlightActiveLine={true}
                style={{ marginTop: "12pt" }}
              />
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5}>
          <InputLabel>Preview</InputLabel>
          <iframe
            id="template-frame"
            title="Template"
            style={{
              width: templateData.box.width,
              height: templateData.box.height,
              zoom: 0.75,
              transform: "scale(0.75)",
              transformOrigin: "0 0",
              overflow: "scroll",
              // marginTop: '12pt',
              marginBottom: "12pt",
            }}
            srcDoc={safeMustacheRender(templateData.template, parsedArgs || {})}
          ></iframe>
        </Grid>
        <Box sx={{ marginBottom: "10vmin" }} />
      </Grid>
      <Snackbar
        open={showMsg.isShown}
        autoHideDuration={6000}
        onClose={() => {
          setShowMsg({ ...showMsg, isShown: false });
        }}
        sx={{ width: "100%", padding: "3vmin", justifyContent: "end" }}
      >
        {
          <Alert
            onClose={() => {
              setShowMsg({ ...showMsg, isShown: false });
            }}
            sx={{ marginRight: "3vmin" }}
            severity={showMsg.type}
          >
            {showMsg.message}
          </Alert>
        }
      </Snackbar>
    </div>
  ) : (
    <Loader />
  );
}

export default SandboxRoute;
