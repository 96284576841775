import { HttpsCallableResult } from "firebase/functions";
import { useCallableFn } from "../utils/useCallableFn";

const methodName = "v1_admin_upsert_public_template" as const;

export type UpsertPublicTemplateResult = HttpsCallableResult<{ id: string }>;
export type UpsertPublicTemplateData = {
  id: string;
  name: string;
  html: string;
  arguments: { [key: string]: any };
  width: number;
  height: number;
};

export const useUpsertPublicTemplate = () =>
  useCallableFn<UpsertPublicTemplateData, UpsertPublicTemplateResult>(
    methodName
  );
