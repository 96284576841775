import moment, { Moment } from 'moment-timezone';
import { Timestamp } from 'firebase/firestore';
import { randomStr } from '../../../../utils/randomStr';

export class StatisticsModel {
  constructor({
    id,
    requests = {},
    lastRequest,
  }: {
    id: string;
    requests: { [key: string]: { [key: string]: number } };
    lastRequest?: Moment;
  }) {
    this.id = id;
    this.requests = requests;
    this.lastRequest = lastRequest ? lastRequest : moment(0);
  }

  id: string;

  requests: { [key: string]: { [key: string]: number } };

  lastRequest: Moment;

  static fromJson(json: { [key: string]: any }): StatisticsModel {
    return new StatisticsModel({
      id: typeof json.id === 'string' ? json.id : randomStr(20),
      requests: typeof json?.requests === 'object' ? json.requests : {},
      lastRequest: moment((json.lastRequest as Timestamp).toMillis()),
    });
  }

  toJson(): { [key: string]: any } {
    return {
      id: this.id,
      requests: this.requests,
      lastRequest: Timestamp.fromMillis(this.lastRequest.valueOf()),
    };
  }
}
