import { Box, Typography } from "@mui/material";
import "./StatusRoute.css";
import { useAsyncFn } from "react-use";
import { useEffect } from "react";
import Loader from "../components/Loader";
import FirebaseAnalytics from "../services/FirebaseAnalytics";
import { logEvent } from "firebase/analytics";

interface StatusResponse {
  available: boolean;
  ping: number;
  time: string;
}

function StatusRoute() {
  const checkUrl =
    "https://api.ireceipt.pro/v1/check?t=cd310dc7-af33-4c2a-a3e1-0133b542972d";
  const [state, doFetch] = useAsyncFn(async () => {
    const response = await fetch(checkUrl);
    const result = await response.json();
    return result as StatusResponse;
  }, [checkUrl]);

  useEffect(() => {
    doFetch()
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        logEvent(FirebaseAnalytics, "check_status");
      });
  }, [doFetch]);

  return !state.loading ? (
    <div className="StatusRoute">
      <Typography component={"h5"} textAlign={"center"}>
        You can check the status here:{" "}
        <a
          href="https://status.ireceipt.pro"
          target="_blank"
          rel="norefferer noreferrer"
        >
          status.ireceipt.pro
        </a>
      </Typography>
      <Typography component={"h5"}>
        Status:{" "}
        <text style={{ color: state.value?.available ? "green" : "red" }}>
          {state.value?.available ? "Available" : "Error"}
        </text>
      </Typography>
      <Typography component={"h5"}>Time: {state.value?.ping} ms</Typography>
      <Typography component={"h5"}>Date: {state.value?.time}</Typography>
      <Box sx={{ marginBottom: "10vmin" }} />
    </div>
  ) : (
    <Loader />
  );
}

export default StatusRoute;
