import {
  DocumentReference,
  QueryDocumentSnapshot,
  DocumentSnapshot,
  Transaction,
  collection,
  doc,
  getDoc,
} from "firebase/firestore";
import FirebaseFirestore from "../../../services/FirebaseFirestore";
import { StatisticsModel } from "./Statistics";
import { SubscriptionModel } from "./Subscription";
import { TokenModel } from "./Token";
import { PrivateTemplatesModel } from "./PrivateTemplates";

export class UserModel {
  constructor({
    id,
    email,
    displayName,
    statistics,
    subscription,
    fcmToken,
    tokens,
    domains,
    templates,
  }: {
    id?: string;
    email: string;
    displayName?: string;
    statistics?: StatisticsModel;
    subscription?: SubscriptionModel;
    fcmToken?: string;
    tokens?: TokenModel[];
    domains?: RegExp[];
    templates?: PrivateTemplatesModel;
  }) {
    this.id = id;
    this.email = email;
    this.displayName = displayName;
    this.statistics = statistics;
    this.subscription = subscription;
    this.fcmToken = fcmToken;
    this.tokens = tokens || [];
    this.domains = domains || [];
    this.templates = templates || new PrivateTemplatesModel({});
  }

  id?: string;

  email: string;

  displayName?: string;

  statistics?: StatisticsModel;

  subscription?: SubscriptionModel;

  fcmToken?: string;

  tokens: TokenModel[];

  domains: RegExp[];

  templates: PrivateTemplatesModel;

  static fromJson(id: string, json: { [key: string]: any }): UserModel {
    return new UserModel({
      id: id,
      email: typeof json?.email === "string" ? json.email : "",
      displayName:
        typeof json?.displayName === "string" ? json.displayName : "",
      statistics:
        typeof json.statistics === "object"
          ? StatisticsModel.fromJson(json.statistics)
          : undefined,
      subscription:
        typeof json.subscription === "object"
          ? SubscriptionModel.fromJson(json.subscription)
          : undefined,
      fcmToken: typeof json?.fcmToken === "string" ? json.fcmToken : "",
      tokens: Array.isArray(json.tokens)
        ? json.tokens.map((e) => TokenModel.fromJson(e))
        : [],
      domains: Array.isArray(json.domains)
        ? json.domains.map((e) => new RegExp(e, "i"))
        : [],
      templates: json.templates
        ? PrivateTemplatesModel.fromJson(json.templates)
        : new PrivateTemplatesModel({}),
    });
  }

  toJson(): { [key: string]: any } {
    return {
      email: this.email,
      displayName: this.displayName,
      statistics: this.statistics?.toJson(),
      subscription: this.subscription?.toJson(),
      fcmToken: this.fcmToken,
      tokens: Array.isArray(this.tokens)
        ? this.tokens.map((e) => e.toJson())
        : [],
      domains: Array.isArray(this.domains)
        ? this.domains.map((e) => e.source)
        : [],
      templates: this.templates ? this.templates.toJson() : {},
    };
  }

  static parent = collection(
    FirebaseFirestore,
    "users"
  ).withConverter<UserModel>({
    toFirestore: (doc: UserModel) => doc.toJson(),
    fromFirestore: (snapshot: QueryDocumentSnapshot) =>
      UserModel.fromJson(snapshot.id, snapshot.data()),
  });

  static withId = (id: string): Promise<DocumentSnapshot<UserModel>> =>
    getDoc(doc(UserModel.parent, id));

  ref = (): DocumentReference<UserModel> => {
    if (typeof this.id === "string") return doc(UserModel.parent, this.id);
    const docRef = doc(UserModel.parent);
    this.id = docRef.id;
    return docRef;
  };

  load = (transaction?: Transaction): Promise<DocumentSnapshot<UserModel>> =>
    transaction instanceof Transaction
      ? transaction.get(this.ref())
      : getDoc(this.ref());
}
