export interface ViewPort {
  width: number,
  height: number,
  deviceScaleFactor: number,
}

export const maxViewPorts = {
  pdf: {
    width: 5000,
    height: 5000,
  },
  jpg: {
    width: 3200,
    height: 3200,
  },
} as const;

export const paidViewPorts = {
  pdf: {
    width: 1200,
    height: 1200,
  },
  jpg: {
    width: 1200,
    height: 1200,
  },
} as const;

// 37.938105 px in sm
export const viewPorts = {
  "a1-portrait": {
    width: 2254,
    height: 3190,
    deviceScaleFactor: 1,
  } as ViewPort,
  "a1-landscape": {
    width: 3190,
    height: 2254,
    deviceScaleFactor: 1,
  } as ViewPort,
  "a2-portrait": {
    width: 1592,
    height: 2254,
    deviceScaleFactor: 1,
  } as ViewPort,
  "a2-landscape": {
    width: 2254,
    height: 1592,
    deviceScaleFactor: 1,
  } as ViewPort,
  "a3-portrait": {
    width: 1126,
    height: 1592,
    deviceScaleFactor: 1,
  } as ViewPort,
  "a3-landscape": {
    width: 1592,
    height: 1126,
    deviceScaleFactor: 1,
  } as ViewPort,
  "a4-portrait": {
    width: 796,
    height: 1126,
    deviceScaleFactor: 1,
  } as ViewPort,
  "a4-landscape": {
    width: 1126,
    height: 796,
    deviceScaleFactor: 1,
  } as ViewPort,
  "a5-portrait": {
    width: 562,
    height: 796,
    deviceScaleFactor: 1,
  } as ViewPort,
  "a5-landscape": {
    width: 796,
    height: 562,
    deviceScaleFactor: 1,
  } as ViewPort,
  "a6-portrait": {
    width: 398,
    height: 562,
    deviceScaleFactor: 1,
  } as ViewPort,
  "a6-landscape": {
    width: 562,
    height: 398,
    deviceScaleFactor: 1,
  } as ViewPort,
  "a7-portrait": {
    width: 280,
    height: 398,
    deviceScaleFactor: 1,
  } as ViewPort,
  "a7-landscape": {
    width: 398,
    height: 280,
    deviceScaleFactor: 1,
  } as ViewPort,
} as const;
