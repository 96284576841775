import {
  Alert,
  Button,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import "./TokensRoute.css";
import Loader from "../components/Loader";
import { Moment, isMoment } from "moment-timezone";
import { UserModel } from "../models/database/User";
import { useState } from "react";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useCreateToken } from "../methods/createToken";
import { useDeleteToken } from "../methods/deleteToken";
import { User } from "firebase/auth";
import { getErrorFromCallable } from "../utils/getErrorFromCallable";
import FirebaseAnalytics from "../services/FirebaseAnalytics";
import { logEvent } from "firebase/analytics";

const getDateStr = (date: Moment | any) => {
  if (isMoment(date)) return <div>{date.format("YYYY-MM-DD")}</div>;
  return <div>-</div>;
};

function TokensRoute({
  authUser,
  user,
  isLoadingUser,
}: {
  authUser: User | null | undefined;
  user: UserModel | undefined;
  isLoadingUser: boolean;
}) {
  const [showMsg, setShowMsg] = useState(
    {} as {
      type: "success" | "warning" | "info" | "error";
      message: string;
      isShown: boolean;
    }
  );

  const createToken = useCreateToken();
  const deleteToken = useDeleteToken();

  return authUser?.emailVerified && !isLoadingUser && user ? (
    <div className="TokensRoute">
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <div />
        <Button
          sx={{ marginLeft: "auto" }}
          variant="outlined"
          color="primary"
          onClick={async () => {
            logEvent(FirebaseAnalytics, "add_token");
            createToken[0]({})
              .then(() => {
                if (!createToken[2])
                  setShowMsg({
                    type: "success",
                    message: "Created!",
                    isShown: true,
                  });
                else
                  setShowMsg({
                    type: "error",
                    message: getErrorFromCallable(createToken[2]).message,
                    isShown: true,
                  });
              })
              .catch((err: Error) => {
                setShowMsg({
                  type: "error",
                  message: err.message || "Unknown error",
                  isShown: true,
                });
              });
          }}
          disabled={createToken[1] || deleteToken[1]}
        >
          <AddCircleOutlineOutlinedIcon />
        </Button>{" "}
      </Toolbar>
      {user.tokens?.length === 0 ? (
        <center>
          <Typography>You have not created any tokens.</Typography>
        </center>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell align="center">DESCRIPTION</TableCell>
                <TableCell align="center">LAST USE</TableCell>
                <TableCell align="center">VALID TO</TableCell>
                <TableCell align="center">COPY</TableCell>
                <TableCell align="center">REMOVE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {user.tokens?.map((e) => (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  key={`tokens_table_row_${e.id}`}
                >
                  <TableCell
                    align="center"
                    key={`tokens_table_row_${e.id}_cell_id`}
                  >
                    {e.id}
                  </TableCell>
                  <TableCell
                    align="center"
                    key={`tokens_table_row_${e.id}_cell_description`}
                  >
                    {e.description}
                  </TableCell>
                  <TableCell
                    align="center"
                    key={`tokens_table_row_${e.id}_cell_lastuse`}
                  >
                    {getDateStr(e.lastRequest)}
                  </TableCell>
                  <TableCell
                    align="center"
                    key={`tokens_table_row_${e.id}_cell_validto`}
                  >
                    {getDateStr(e.validTo)}
                  </TableCell>
                  <TableCell
                    align="center"
                    key={`tokens_table_row_${e.id}_cell_copy`}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={async () => {
                        await navigator.clipboard
                          .writeText(e.token || "")
                          .then(() => {
                            setShowMsg({
                              type: "success",
                              message: "Copied!",
                              isShown: true,
                            });
                          })
                          .catch((err: Error) => {
                            setShowMsg({
                              type: "error",
                              message: err.message || "Unknown error",
                              isShown: true,
                            });
                          });
                      }}
                      disabled={createToken[1] || deleteToken[1]}
                      key={`tokens_table_row_${e.id}_cell_copy_button`}
                    >
                      <ContentCopyOutlinedIcon
                        key={`tokens_table_row_${e.id}_copy_button_icon`}
                      />
                    </Button>
                  </TableCell>
                  <TableCell
                    align="center"
                    key={`tokens_table_row_${e.id}_delete`}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={async () => {
                        logEvent(FirebaseAnalytics, "remove_token");
                        await deleteToken[0]({ id: e.id as string })
                          .then(() => {
                            if (!deleteToken[2])
                              setShowMsg({
                                type: "success",
                                message: "Removed!",
                                isShown: true,
                              });
                            else
                              setShowMsg({
                                type: "error",
                                message: getErrorFromCallable(deleteToken[2])
                                  .message,
                                isShown: true,
                              });
                          })
                          .catch((err: Error) => {
                            setShowMsg({
                              type: "error",
                              message: err.message || "Unknown error",
                              isShown: true,
                            });
                          });
                      }}
                      disabled={createToken[1] || deleteToken[1]}
                      key={`tokens_table_row_${e.id}_delete_button`}
                    >
                      <DeleteOutlineOutlinedIcon
                        key={`tokens_table_row_${e.id}_delete_button_icon`}
                      />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Snackbar
        open={showMsg.isShown}
        autoHideDuration={6000}
        onClose={() => {
          setShowMsg({ ...showMsg, isShown: false });
        }}
        sx={{ width: "100%", padding: "3vmin", justifyContent: "end" }}
      >
        {
          <Alert
            onClose={() => {
              setShowMsg({ ...showMsg, isShown: false });
            }}
            sx={{ marginRight: "3vmin" }}
            severity={showMsg.type}
          >
            {showMsg.message}
          </Alert>
        }
      </Snackbar>
    </div>
  ) : (
    <Loader />
  );
}

export default TokensRoute;
