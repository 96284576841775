import { Alert, Box, Snackbar, Typography } from "@mui/material";
import "./ApiRoute.css";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { User, sendEmailVerification } from "firebase/auth";
import { useState } from "react";
import { errorMessagesMap } from "../services/FirebaseAuth";
import FirebaseAnalytics from "../services/FirebaseAnalytics";
import { logEvent } from "firebase/analytics";

function ApiRoute({ authUser }: { authUser: User | null | undefined }) {
  const [showMsg, setShowMsg] = useState(
    {} as {
      type: "success" | "warning" | "info" | "error";
      message: string;
      isShown: boolean;
    }
  );
  return (
    <div className="ApiRoute">
      {authUser?.emailVerified ? null : (
        <Typography textAlign={"center"}>
          {authUser
            ? "Confirm your email by clicking on the link from your email to create token or CORS policies."
            : "Log in or register to create token or CORS policies."}
          {authUser ? (
            <Typography
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                sendEmailVerification(authUser, {
                  url: `${window.location.origin}`,
                })
                  .then(() => {
                    setShowMsg({
                      type: "success",
                      message: `The letter has been sent to ${authUser.email}`,
                      isShown: true,
                    });
                  })
                  .catch((err) => {
                    if (errorMessagesMap[err.code]) {
                      setShowMsg({
                        type: "error",
                        message: `${errorMessagesMap[err.code]}`,
                        isShown: true,
                      });
                    } else {
                      setShowMsg({
                        type: "error",
                        message: `${err?.message}`,
                        isShown: true,
                      });
                    }
                  });
              }}
            >
              (resend confirmation email)
            </Typography>
          ) : null}
        </Typography>
      )}
      <SwaggerUI
        url="https://api.ireceipt.pro/openapi.yml"
        persistAuthorization={true}
        defaultModelExpandDepth={-1}
        docExpansion="none"
        requestInterceptor={(req) => {
          logEvent(FirebaseAnalytics, "swagger_request", {
            request_url: req.path,
          });
          return req;
        }}
      />
      <Box sx={{ marginBottom: "10vmin" }} />
      <Snackbar
        open={showMsg.isShown}
        autoHideDuration={6000}
        onClose={() => {
          setShowMsg({ ...showMsg, isShown: false });
        }}
        sx={{ width: "100%", padding: "3vmin", justifyContent: "end" }}
      >
        {
          <Alert
            onClose={() => {
              setShowMsg({ ...showMsg, isShown: false });
            }}
            sx={{ marginRight: "3vmin" }}
            severity={showMsg.type}
          >
            {showMsg.message}
          </Alert>
        }
      </Snackbar>
    </div>
  );
}

export default ApiRoute;
